/*******************************
          General
*******************************/

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.parent-container {
  display: flex;
}

.container {
  margin: 30px auto;
  overflow: auto;
  /* border: 1px solid steelblue; */
  padding: 30px;
  border-radius: 5px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

/*******************************
          New
*******************************/

.link{
  color: inherit;
  text-decoration: none;
}

.link:hover{
  color: #333;
}

.link:visited{
  color: #333;
}

.hover-btn {
  background-color: white;
  border-radius: 50%;
  border: none;
  height: 2em;
  width: 2em;
  padding: 0.4em;
  transition: background 0.2s ease-in-out;

}
.hover-btn:hover {
  background-color: rgb(241, 241, 241);
  /* color: white; */
}

.add-btn {
  outline: none;
  border: none;
  background-color: white;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  padding: 4px 8px;
  transition: background 0.2s ease-in-out;
}

.add-btn:hover {
  background-color: rgb(241, 241, 241);
}


#preview-btn {
  padding: 0;
}

#preview-btn:focus {
  border: none;
}

