.login {
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.login h1 {
  margin: 0px 0px 32px;
  font-size: 1.6em;
}

.wrapper {
  width: 100%;
  max-width: 320px;
  display: flex;
  flex-direction: column;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.center {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.or {
  padding: 10px;
  color: gray;
  background-color: white;
  font-size: 0.75em;
}

.line {
  height: 0.5px;
  background-color: lightgray;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: -1;
}

.loginButton {
  height: 48px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: white;
  border-radius: 8px;
  padding: 12px;
  text-align: center;
}

.loginButton img {
  height: 24px;
}

.google {
  background-color: #df4930;
}
.facebook {
  background-color: #507cc0;
}
.github {
  background-color: black;
}

.submit {
  background-color: black;
  color: white;
  border-radius: 8px;
  padding: 12px;
  height: 48px;
  margin: 24px 0px 0px;
}
button {
  outline: none;
  border: none;
}
button:active {
  border-style: outset;
}

textarea:focus,
input:focus {
  outline: none;
}

.signup-wrapper input,
.right input {
  padding: 12px 0px;
  border: none;
  border-bottom: 2px solid rgb(210, 210, 210);
  transition: border-bottom 0.2s;
}

.right input:focus {
  border-bottom: 2px solid black;
}

.signup {
  text-align: center;
}

/* wide screen */
@media screen and (min-width: 992px) {

  .wrapper {
    width: 100%;
    max-width: 640px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }

  .left,
  .right {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1;
  }

  .center {
    height: 100%;
    width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .or {
    padding: 10px;
    color: gray;
    background-color: white;
    font-size: 0.75em;
  }

  .line {
    height: 100%;
    width: 0.5px;
    background-color: lightgray;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    /* z-index: -1; */
  }

  .loginButton {
    height: 48px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    color: white;
    border-radius: 8px;
    padding: 12px;
    text-align: center;
  }

  .loginButton img {
    height: 24px;
  }

  .submit {
    background-color: black;
    color: white;
    border-radius: 8px;
    padding: 12px;
    height: 48px;
    margin: 24px 0px 0px;
  }
}
