
  
  .preview :is(h1, h2, h3, h4, h5, h6) {
    color: #0d6efd;
    margin: 20px 0px;
    font-weight: 700;
  }
  
  .preview :is(code) {
    /* color: black;
    display: flex;
    padding: 10px 15px;
    border-radius: 5px;
    background: rgb(196, 193, 193); */
    font-family: 'Menlo', 'Courier New', Courier, monospace;
    font-weight: 400;
  }

  /* .preview input[type=checkbox] {
    outline: solid 1px #0d6efd;
    background-color: aqua;
  } */

  .preview :is(pre) {
    border: none;
    padding: 20px 24px;
    background-color: #494A55 !important;
    color: #f0f0f0;
    margin: 24px;
  }

  .preview :is(hr) {
    margin: 2.4em 0px;
  }
