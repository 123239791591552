
.navbar {
    height: 50px;
    /* background-color: rgba(102, 16, 83, 0.698); */
    /* color: white; */
    color: #333;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-radius: 0px;
    margin: 10px 20px;
    gap: 10px;
  }
  
  .logo {
    font-family: Arial;
    font-weight: 700;
    flex-grow: 1;
  }

  .logo a {
    font-size: 20px;
  }
  
  .avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
  }

  .grizzlyicon{
    width: 30px;
    height: 30px;
    margin-right: 1px;
  }
  