/*******************************
          Editor 
*******************************/

.editor-parent {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  padding: 0px 10px;
}

.editor-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    flex-grow: 1;
    padding: 0px 6% 0px 4%;
  }

  .nav-menu {
    visibility: collapse;
    width: 0;
    opacity: 0;
    transition: width 1s;
  }

  .nav-menu.active {
    visibility: visible;
    width: 120px;
    opacity: 1;
    transition: width 1s, opacity 3s;
  }

  .nav-menu-items {
    padding: 0;
    margin: 0;
    width: 100%;
    min-width: 5vw;
  }

  .sidebar-note {
    color: black;
    border-radius: 10px;
    padding: 8px;
    overflow: hidden;
    transition: background 0.2s ease-in-out;
  }

  .sidebar-note:hover {
    background-color: rgb(200, 200, 200);
    color: white;
  }

  .sidebar-note.current-note {
    font-weight: 700;
  }

  .sidebar-button {
    color: white;
    text-align: center;
    border-radius: 10px;
    background-color: rgb(193, 193, 193);
    padding: 8px;
    overflow: hidden;
    margin: 0px;
    transition: background 0.2s ease-in-out;
  }

  .sidebar-button:hover {
    background-color: rgb(132, 132, 132);
    color: white;

  }

  /* .preview-btn:focus {
    outline: none;
    color: white;
  } */

  .preview-toggle {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 4px;
    /* border: solid lightgray; */
    /* padding: 4px 4px; */
    border-radius: 8px;
    align-items: center;
    /* overflow: hidden; */
    margin: 0px 10px;
  }

  .preview-icon.active {
    background-color: gray;
    color: white;
  }

  .preview-icon.inactive{
    transition: all 0.2s;
    border: solid 2px lightgray;
  }
  .preview-icon.inactive:hover {
    background-color: rgb(241, 241, 241);
  }

  .preview-icon {
    height: 100%;
    width: 32px;
    padding: 6px;
    border-radius: 6px;
  }

  .separator {
    height: 90%;
    width: 2px;
    background-color: rgb(196, 196, 196);
  }


  /* .sidebar-note.current-note:hover {
    background-color: pink;
  } */
  
  .editor {
    /* height: max-content; */
    /* display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 10px; */
  }

  .title {
    font-size: 16px;
  }

  #title-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
  }

  #title-heading {
    display: inline-block;
    vertical-align: middle;
    margin: 0;
    font-weight: 600;
    font-size: inherit;
  }

  #title-input {
    display: inline;
    border: none;
    padding: 0;
    flex-grow: 1;
    font-size: 2em;
    font-weight: 600;
  }

  #title-input:focus {
    outline-width: 0;
  }
  
  textarea {
    height: 80vh; /* min height 80vh or text height */
    width: 100%;
    overflow: auto;
    border: none;
    outline: none;
  }
  
  .editor-child {
    /* width: 100%;
    max-width: 100vw; */
    margin-bottom: 10vh;
    display: flex;
    flex-direction: row;
    /* flex: 1 1 50%; */
  }
  
  .text-display {
    overflow: hidden;
    resize: none;
    flex: 1 0 0%;
    /* transition: all 0.2s ease-in-out; */
  }

  .text-display.inactive {
    /* flex-grow: 0.000001; */
    display: none;
  }